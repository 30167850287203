<template>
  <div class="d-flex align-items-center justify-content-center">
    <div v-if="!isLoading">
      <div v-if="locations.length == 0">
        <h5>No locations have been added by the selected user</h5>
      </div>
      <table class="select-list-wrapper" v-else>
        <thead class="select-label">
          <tr>
            <th class="selected-counter-max align" style="text-align: center">
              <h5>Room</h5>
            </th>
            <th v-if="isActiveAutoCheckInModule" class="selected-counter-max">
              <h5>Student Check-in Pin</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(location, index) in locations" :key="index">
            <td class="selected-counter-max">
              {{ location?.room?.name }}
            </td>
            <td
              v-if="isActiveAutoCheckInModule"
              class="selected-counter-max"
              style="text-align: center"
            >
              {{ location?.room_pin || "----" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <CSpinner color="primary" />
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue"
import { useStore } from "vuex"
export default {
  name: "UserLocationDetails",
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const store = useStore()

    const isLoading = ref(true)

    const locations = ref([])

    const isActiveAutoCheckInModule = computed(
      () => store.getters["schools/isActiveAutoCheckInModule"]
    )

    onMounted(() => {
      isLoading.value = true
      store.dispatch("rooms/getUserLocations", props.user).then((response) => {
        locations.value = response?.data?.data?.assigned_rooms
        isLoading.value = false
      })
    })

    return {
      locations,
      isLoading,
      isActiveAutoCheckInModule
    }
  }
}
</script>
<style scoped>
i {
  color: #0073e6;
  border-radius: 50%;
  padding: 0 3px;
  font-size: 20px;
  margin-left: 4px;
}
td {
  text-align: center;
}
</style>
