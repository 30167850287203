<template>
  <div v-if="!isLoading" class="mt-5">
    <div v-if="userRole !== 'student'">
      <Label>Title</Label>
      <CustomSelect
        :filterable="true"
        :close-on-select="true"
        :options="titles"
        v-model="state.form.title"
        :disabled="true"
      ></CustomSelect>
    </div>
    <div class="mt-3">
      <Label required>First name</Label>
      <InputField
        :disabled="true"
        v-model="state.form.firstName"
        class="d-block"
      ></InputField>
    </div>
    <div class="mt-3">
      <Label required>Last name</Label>
      <InputField
        :disabled="true"
        v-model="state.form.lastName"
        class="d-block"
      ></InputField>
    </div>
    <div v-if="userRole === 'student'" class="mt-3">
      <Label required>Email</Label>
      <InputField
        :disabled="true"
        v-model="state.email"
        class="d-block"
      ></InputField>
    </div>
    <div v-if="isVisibleAllowAppRequest && userRole !== 'student'" class="mt-4">
      <div class="d-flex justify-content-between py-2">
        <Label>Appointment requests to you</Label>
        <div class="pe-none">
          <CustomSwitch
            type="radio"
            v-model="state.form.allowAppRequest"
          ></CustomSwitch>
        </div>
      </div>
    </div>
    <div v-if="userRole !== 'student'" class="mt-4">
      <div class="d-flex justify-content-between py-2">
        <Label>Passes to/from you</Label>
        <div class="pe-none">
          <CustomSwitch
            type="radio"
            v-model="state.form.allowCreatePasses"
          ></CustomSwitch>
        </div>
      </div>
    </div>
    <div v-if="userRole !== 'student'" class="mt-4">
      <Label>PIN</Label>
      <InputField
        placeholder="PIN will be displayed here."
        :disabled="true"
        v-model="state.form.pin"
        class="d-block"
      ></InputField>
    </div>
    <div
      v-if="
        userRole === 'student' &&
        isActiveKioskModule &&
        !activeSchool.hide_student_kiosk_passcode_field
      "
      class="mt-5"
    >
      <Label>Kiosk passcode</Label>
      <InputField
        placeholder="Enter Passcode"
        :disabled="true"
        class="d-block"
      ></InputField>
    </div>
  </div>
  <div v-else class="d-flex justify-content-center">
    <CSpinner color="primary" />
  </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from "vue"
import { useStore } from "vuex"
import Label from "@/v3components/shared/Form/Label"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import InputField from "@/v3components/shared/Form/InputField"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"

export default {
  name: "UserProfileDetails",
  components: {
    Label,
    CustomSelect,
    InputField,
    CustomSwitch
  },
  props: {
    user: {
      type: Object
    },
    avatar: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: ""
    },
    activeAppoinmentModuleOptions: {
      type: Object
    },
    isActiveAppoinmentModule: {
      type: Boolean,
      default: false
    },
    isActiveKioskModule: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      form: {
        title: "",
        firstName: "",
        lastName: "",
        newPass: "",
        allowAppRequest: true,
        allowCreatePasses: true,
        pin: ""
      },
      email: props.user.email,
      isProcessing: false,
      serverErrors: {},
      serverRes: null
    })

    const isLoading = ref(true)

    const titles = [
      { value: null, label: "None" },
      { value: "Mr.", label: "Mr." },
      { value: "Mrs.", label: "Mrs." },
      { value: "MSgt", label: "MSgt" },
      { value: "Ms.", label: "Ms." },
      { value: "Mx.", label: "Mx." },
      { value: "Miss", label: "Miss" },
      { value: "Dr.", label: "Dr." },
      { value: "Principal", label: "Principal" },
      { value: "Assistant Principal", label: "Assistant Principal" },
      { value: "Dean of Students", label: "Dean of Students" },
      { value: "Br.", label: "Br." },
      { value: "Sr.", label: "Sr." },
      { value: "Cpt", label: "Cpt" },
      { value: "Maj", label: "Maj" },
      { value: "Lt Col", label: "Lt Col" },
      { value: "Col", label: "Col" },
      { value: "Sgt", label: "Sgt" },
      { value: "SSG", label: "SSG" },
      { value: "SFC", label: "SFC" },
      { value: "Coach", label: "Coach" },
      { value: "Rabbi", label: "Rabbi" }
    ]

    onMounted(() => {
      isLoading.value = true
      store.dispatch("users/getSpecificUser", props.user).then((response) => {
        const profileData = {
          firstName: response.data.data.first_name,
          lastName: response.data.data.last_name,
          title:
            response.data.data.user_initials &&
            response.data.data.user_initials !== "null"
              ? response.data.data.user_initials
              : titles[0],
          allowAppRequest: !!response.data.data.allow_appointment_requests,
          allowCreatePasses: !!response.data.data.allow_passes_to_me,
          pin: response.data.data.pin
        }
        isLoading.value = false
        state.form = Object.assign(state.form, profileData)
      })
    })

    const isVisibleAllowAppRequest = computed(() => {
      return (
        props.isActiveAppoinmentModule &&
        props.activeAppoinmentModuleOptions &&
        props.activeAppoinmentModuleOptions[props.userRole]
      )
    })

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    return {
      state,
      titles,
      isVisibleAllowAppRequest,
      activeSchool,
      isLoading
    }
  }
}
</script>
