<template>
  <button
    ref="actionButton"
    class="v3-icon-button lh-normal"
    @click="activateToggleModal"
    :class="{ active, smaller, middle }"
  >
    <i :class="icon"></i><span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
import { ref } from "vue"

export default {
  props: {
    icon: String,
    text: String,
    active: Boolean,
    smaller: Boolean,
    middle: Boolean
  },
  setup() {
    const actionButton = ref(null)

    const activateToggleModal = () => {
      actionButton.value.focus()
    }

    return {
      actionButton,
      activateToggleModal
    }
  }
}
</script>
